@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.about {
  padding: rem(53) 0 rem(64);

  &__content {
    margin-bottom: 39px;

    p {
      max-width: 740px;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__desc {
    padding: 24px;
    width: 100%;
    max-width: 445px;
  }

  h3 {
    margin-bottom: 24px;
  }

  p {
    &:first-of-type {
      margin-bottom: 20px;
    }
  }

  @include media(tablet) {
    padding: 30px 0;
    
    &__inner {
      flex-direction: column;
    }

    &__content {
      margin-bottom: 30px;

      p {
        max-width: 100%;
      }
    }

    &__desc {
      padding: 0;
      max-width: 100%;
    }

    &__image {
      img {
        width: 100%;
      }
    }
  }
}
