/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --content-width: 1312px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Inter", sans-serif;
  --font-family-secondary: "Raleway", sans-serif;

  // font-weight
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-900: 900;

  //
  --color-white: #fff;
  --color-black: #000;
  --color-gray-100: #f3f5f5;
  --color-gray-300: #f3f5f5;
  --color-blue-300: #31a0fee5;
  --color-blue-600: #235270;
  --color-red-400: #ff0000;
  --color-brown-300: #F7E1B5;

  //  radius
  --radius-main: 8px;
  --radius-big: 24px;
}
