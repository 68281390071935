@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.choose {
  $root: &;

  &__content {
    margin-bottom: 24px;

    p {
      font-weight: var(--fw-400);
      font-size: 20px;
    }
  }

  &__inner {
    display: flex;
    gap: 32px;
  }

  &__desc {
    width: 100%;
    &:first-of-type {
      padding: 24px;
      max-width: 493px;
    }

    &:last-of-type {
      .choose__list {
        margin-bottom: 24px;
        padding: 24px;
      }
    }
  }

  &__list {
    display: grid;
    gap: 14px;
  }

  h2 {
    margin-bottom: 24px;
  }

  h3 {
    font-family: var(--font-family-primary);
    font-weight: var(--fw-700);
    font-size: 17px;
    line-height: 1.5;
    text-transform: none;
  }

  &--external {
    #{$root}__content {
      p {
        max-width: 640px;
      }
    }

    h2 {
      margin-bottom: 24px;
    }
  }

  @include media(tablet-wide) {
    &__desc {
      &:first-of-type {
        padding: 5px;
      }

      &:last-of-type {
        .choose__list {
          padding: 0;
        }
      }
    }

    &__list {
      padding: 0;
    }
  }

  @include media(mobile-l) {
    &__inner {
      flex-direction: column;
      gap: 16px;
    }

    &__desc {
      &:first-of-type {
        max-width: 100%;
      }
    }
  }
}
