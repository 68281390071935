@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-300);
  font-style: normal;
  font-size: clamp(16px, 1vw, 20px);
  line-height: 1;
  letter-spacing: 0;
  color: var(--color-black);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  scroll-padding: var(--header-height);

  &__body {
    position: relative;
    min-width: 320px;
    min-height: var(--vh);

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      background-color: rgba(39, 40, 43, 0.75);
      opacity: 0;
      transition: opacity 0.3s;
      inset: 0;
    }
  }

  &.open {
    overflow: hidden;

    > .page__body::before {
      z-index: 5;
      opacity: 1;
    }
  }
}

.section {
  padding: rem(64) 0;

  @include media(tablet-wide) {
    padding: 45px 0;
  }

  @include media(tablet) {
    padding: 30px 0;
  }
}

.container,
.wrapper {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.wrapper {
  max-width: 1248px;
}

.image {
  overflow: hidden;
  border-radius: var(--radius-main);
}

.site-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  min-height: var(--vh);
}

.main {
  padding-top: calc(var(--header-height) + 24px);
}

.logo {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-right: rem(64);
  width: 100%;
  max-width: 236px;

  @include media(tablet) {
    margin-right: 25px;
    max-width: 200px;
  }
}

.content {
  display: flex;
  align-items: center;
  margin: 0 auto rem(64);
  padding-left: 48px;
  gap: 60px;

  p {
    width: 100%;
    max-width: 582px;
  }

  @include media(tablet-wide) {
    margin-bottom: 30px;
    padding-left: 0;
    gap: 25px;
  }

  @include media(tablet) {
    flex-direction: column;
  }
}

.is-scrolling {
  overflow: hidden;
  scrollbar-color: var(--color-blue-300) var(--color-white);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: var(--color-white);
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: var(--color-blue-300);
  }

  @include media(desktop) {
    overflow-x: scroll;
    padding: 15px 0;
  }
}
