@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.products {
  padding-bottom: rem(64);

  &__content {
    margin-bottom: 0;
    padding: 31px 48px;

    p {
      max-width: 812px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 8px;
    gap: 8px;
  }

  &__item {
    position: relative;

    &--whole {
      .products__desc {
        left: auto;
        right: 0;
        border-radius: 0 0 0 24px;
      }
    }

    &--unilife {
      .products__desc {
        top: 24px;
        bottom: 0;
        border-radius: 0 24px 0 0;
      }
    }

    &--custom {
      .products__desc {
        left: auto;
        right: 0;
        top: 24px;
        bottom: 0;
        border-radius: 24px 0 0;
      }
    }
  }

  &__desc {
    position: absolute;
    overflow: hidden;
    left: 0;
    top: 0;
    border-radius: 0 0 24px;
    padding: 16px 24px;
    width: 100%;
    max-width: 485px;
    background: linear-gradient(142deg, #fff 0%, rgba(255, 255, 255, 0.5) 100%);

    p {
      font-weight: var(--fw-400);
      font-size: clamp(1rem, 0.9167rem + 0.3704vi, 1.25rem);
      line-height: 1.2;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    h3 {
      margin-bottom: 24px;
    }
  }

  &__description {
    border-radius: 24px 24px 100px 100px;
    padding: 24px 48px;
    color: var(--color-white);
    background: linear-gradient(167deg, #69b35c 0%, #3c7f38 100%);

    p {
      font-weight: var(--fw-400);
      font-size: clamp(1rem, 0.9167rem + 0.3704vi, 1.25rem);
      line-height: 1.2;
      text-align: center;
    }
  }

  @include media(desktop) {
    padding-bottom: 30px;

    &__desc {
      padding: 16px;
    }
  }

  @include media(tablet-wide) {
    &__content {
      flex-direction: column;
      padding: 30px 0;
    }

    &__list {
      grid-template-columns: 1fr;
    }

    img {
      width: 100%;
      min-height: 460px;
      aspect-ratio: 16/9;
    }
  }

  @include media(mobile-l) {
    &__desc {
      max-width: 100%;
    }

    &__description {
      border-radius: 20px 20px 60px 60px;
      padding: 24px;
    }
  }
}
