@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.slider {
  position: relative;
  overflow: hidden;
  padding-bottom: 64px;

  &__content {
    p {
      max-width: 100%;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 2);
    padding: 16px;
    width: 100%;
    height: auto;
    max-width: 320px;
    background-color: var(--color-gray-300);

    p {
      font-size: 16px;
    }
  }

  h3 {
    margin-bottom: 8px;
    font-size: clamp(18px, 1rem + 0.5556vi, 24px);
  }

  .btn {
    position: absolute;
    top: 50%;
    transform: translateY(50%);

    &.swiper-button-disabled {
      opacity: 0.2;
      pointer-events: none;
    }

    &--prev {
      left: 18px;
      scale: -1 1;
    }

    &--next {
      left: auto;
      right: 18px;
    }

    @include media(tablet) {
      &--prev {
        left: 0;
      }

      &--next {
        right: 0;
      }
    }

    @include media(mobile-m) {
       transform: translateY(80%);
    }
  }

  @include media(tablet) {
    padding-bottom: 30px;
  }
}
