@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.missions {
  &__list {
    display: flex;
    gap: 24px;
  }

  &__item {
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 2);
    padding: 24px 24px 32px;
    width: 100%;
    max-width: 442px;
    color: var(--color-white);
    background: linear-gradient(134deg, #ff5c5c 0%, #f33 100%);

    &--colored {
      max-width: 846px;
      background: linear-gradient(332deg, #81d2e4 0%, #469db2 100%);
    }
  }

  h3 {
    margin-bottom: 24px;
    text-transform: capitalize;
  }

  p {
    font-weight: var(--fw-400);
    font-size: clamp(1.125rem, 1.0833rem + 0.1852vi, 1.25rem);
  }

  @include media(tablet) {
    &__list {
      flex-direction: column;
    }

    &__item {
      padding: 16px;
      max-width: 100%;
    }
  }
}
