@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.values {
  padding: 64px 0 90px;

  &__content {
    margin-bottom: 24px;

    p {
      max-width: 768px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 24px;
    gap: 24px;
  }

  &__desc {
    overflow: hidden;
    border-radius: var(--radius-main);
    padding: 24px 46px;
    background-color: var(--color-brown-300);

    p {
      font-weight: var(--fw-400);
      font-size: clamp(1.125rem, 1.0833rem + 0.1852vi, 1.25rem);
      line-height: 1.2;
    }
  }

  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    border-radius: var(--radius-main);
    padding: 8px 10px;
    background-color: var(--color-gray-100);

    p {
      font-weight: var(--fw-400);
      font-size: 17px;
      line-height: 1.5;
    }
  }

  span {
    font-weight: var(--fw-700);
    font-size: 17px;
    line-height: 1.5;
  }

  @include media (tablet-wide) {
    padding: 40px 0;
  }

  @include media(tablet) {
    padding: 30px 0;

    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__desc {
      padding: 16px;
    }

    li {
      &:last-child {
        grid-column: 2 span;
        text-align: center;
      }
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }

    li {
      &:last-child {
        grid-column: auto;
        text-align: left;
      }
    }
  }
}
