@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.types {
  &__wrapper {
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 1px;
      background-color: var(--color-black);
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      background: linear-gradient(360deg, rgba(112, 210, 234, 0.27) 0%, rgba(127, 218, 241, 0.27) 100%);
      inset: 0;
    }
  }

  &__content {
    position: relative;
    margin-bottom: 0;
    padding: 30px 48px;

    .btn {
      margin-left: auto;
      font-weight: var(--fw-400);
      font-size: 24px;

      &::after {
        width: 15px;
        height: 14px;
      }
    }

    p {
      max-width: 431px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
  }

  &__item {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: var(--radius-big);
    padding: 16px;

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      background: linear-gradient(180deg, #9de164 0%, #9adc59 100%);
      inset: 0;
    }

    p {
      position: relative;
      font-weight: 300;
      font-style: italic;
      font-size: 16px;

      &::before {
        content: "";
        position: absolute;
        top: -20px;
        width: 100%;
        height: 1px;
        background-color: var(--color-black);
      }
    }

    &--whole {
      &::before {
        background: linear-gradient(77deg, #81d2e4 0%, #469db2 100%);
      }
    }

    &--uni {
      &::before {
        background: linear-gradient(0deg, #e8d5d4 0%, #d4aea8 37.37%, #ead4d4 100%);
      }
    }

    &--custom {
      &::before {
        background: linear-gradient(110deg, #fbc668 0%, #dbab34 100%);
      }
    }

    .btn {
      margin-bottom: 32px;
      font-weight: var(--fw-400);
    }
  }

  h3 {
    margin-bottom: 32px;
    font-size: clamp(24px, 1rem + 2.2222vi, 48px);
    letter-spacing: -0.05em;
    color: var(--color-white);
  }

  img {
    margin-bottom: 16px;
  }

  @include media(tablet-wide) {
    &__list {
      grid-template-columns: repeat(2, 1fr);

      img {
        width: 100%;
        object-position: center;
        aspect-ratio: 2/1;
      }
    }
  }

  @include media(tablet) {
    &__content {
      padding: 30px 0;
      gap: 16px;
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }

    img {
      aspect-ratio: 2/1;
    }
  }
}
