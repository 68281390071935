@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.benefits {
  margin-bottom: 64px;

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  &__item {
    overflow: hidden;
    border-radius: 16px;
    padding: 24px;
    background-color: var(--color-gray-300);

    ul {
      display: grid;
      padding-left: 27px;
      gap: 18px;

      li {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 7px;
          left: -17px;
          border-radius: 50%;
          width: 7px;
          height: 7px;
          background-color: var(--color-black);
        }
      }

      p,
      span {
        font-size: 18px;
      }

      span {
        font-weight: var(--fw-500);
      }
    }
  }

  h3 {
    margin-bottom: 8px;
    font-size: clamp(1rem, 0.8333rem + 0.7407vi, 1.5rem);
    line-height: 1.5;
  }

  h2 {
    margin-bottom: 32px;
  }

  @include media(tablet) {
    margin-bottom: 30px;

    &__item {
      padding: 16px;
    }
  }

  @include media(mobile-l) {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}
