@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.contacts {
  position: relative;
  z-index: 1;
  padding-bottom: 268px;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    background: linear-gradient(199.23deg, rgb(255, 255, 255) 59.715%, rgb(153, 153, 153) 110.071%);
    inset: 0;
  }

  &__wrapper {
    position: relative;

    img {
      width: 100%;
    }
  }

  &__inner {
    z-index: 1;
    border: 22px solid var(--color-white);
    border-right: 0;
    border-bottom: 0;
    border-radius: 50px;
    padding: 11px 11px 24px 16px;
    width: 100%;
    max-width: 498px;
    background: linear-gradient(117deg, #ff5c5c 0%, #f33 100%);
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 36px;
    gap: 12px;

    &::before {
      content: "";
      position: relative;
      width: 55px;
      height: 55px;
      background-image: url("../images/icons/plus.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }

    h2 {
      font-size: clamp(1.875rem, 1.5417rem + 1.4815vi, 2.875rem);
      color: var(--color-white);
    }
  }

  &__form {
    display: grid;
    width: 100%;
    max-width: 100%;
    gap: 27px;

    label {
      width: 100%;
    }

    input {
      border-bottom: 1px solid var(--color-white);
      padding-bottom: 8px;
      width: 100%;
      font-size: 20px;
      color: var(--color-white);

      &::placeholder {
        font-size: 20px;
        color: var(--color-white);
      }
    }

    .btn {
      border-color: transparent;
      max-width: 100%;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      font-size: 24px;
    }
  }

  &__help {
    position: relative;
    z-index: 1;
    align-self: flex-end;
    overflow: hidden;
    border-radius: 0 0 0 196px;
    padding: 11px 18px 18px 24px;
    width: 651px;
    text-align: right;
    background-color: var(--color-white);
    translate: 0 9px;

    h2 {
      margin-bottom: 10px;
      padding-left: 40px;
      font-variant: small-caps;
      font-size: clamp(1.5625rem, 1.25rem + 1.3889vi, 40px);
      line-height: 1.15;
      letter-spacing: -0.04em;
      text-transform: none;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: end;
      font-weight: var(--fw-500);
      font-size: clamp(1.125rem, 1.0417rem + 0.3704vi, 1.375rem);
      transition: color 0.3s linear;
      gap: 8px;

      &::before {
        content: "";
        position: relative;
        width: 48px;
        height: 48px;
        background-image: url("../images/icons/email.svg");
        background-position: 0 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: var(--color-grey-10);
      }

      &:hover {
        color: var(--color-red-400);
      }
    }
  }

  &__content {
    position: absolute;
    right: 47px;
    top: 0;
    display: flex;
    flex-direction: row-reverse;
    gap: 22px;
  }

  @include media(tablet-wide) {
    padding-bottom: 300px;

    &__help {
      max-width: 560px;
      translate: 0 -40px;

      h2 {
        padding-left: 0;
        font-size: 26px;
      }

      a {
        font-size: 16px;

        &::before {
          width: 24px;
          height: 24px;
        }
      }
    }

    &__inner {
      border: 5px solid var(--color-white);
    }
  }

  @include media(tablet) {
    padding-bottom: 25px;

    &__help {
      align-self: center;
      border-radius: 0 0 196px 196px;
      padding: 16px;
      text-align: center;
      translate: none;

      a {
        justify-content: center;
      }

      h2 {
        width: auto;
        text-align: center;
      }
    }

    &__inner {
      max-width: 550px;
    }

    &__content {
      position: relative;
      right: 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    img {
      margin-bottom: 25px;
      object-position: left;
      aspect-ratio: 17 / 6;
    }
  }

  @include media(mobile-m) {
    &__help {
      border-radius: 0 0 80px 80px;
      max-width: 100%;
    }
  }
}
