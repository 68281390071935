@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.policy {
  &__content {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  h1 {
    margin-bottom: 35px;
    font-size: clamp(1.125rem, 0.8333rem + 1.2963vi, 2rem);
    line-height: 1.3;
  }

  h2 {
    margin-bottom: 24px;
    font-size: clamp(1.125rem, 1rem + 0.5556vi, 1.5rem);
  }

  ul {
    margin-bottom: 20px;
    padding-left: 15px;
  }

  li {
    padding-left: 10px;
    font-size: clamp(16px, 0.9167rem + 0.3704vi, 20px);

    &:not(:last-child) {
      margin-bottom: 3px;
    }

    &::marker {
      content: "✓";
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  a {
    text-decoration: underline;
    color: var(--color-blue-300);
  }
}
