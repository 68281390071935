@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap";
:root {
  --content-width: 1312px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Inter", sans-serif;
  --font-family-secondary: "Raleway", sans-serif;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-900: 900;
  --color-white: #fff;
  --color-black: #000;
  --color-gray-100: #f3f5f5;
  --color-gray-300: #f3f5f5;
  --color-blue-300: #31a0fee5;
  --color-blue-600: #235270;
  --color-red-400: red;
  --color-brown-300: #f7e1b5;
  --radius-main: 8px;
  --radius-big: 24px;
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-300);
  letter-spacing: 0;
  color: var(--color-black);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  scroll-padding: var(--header-height);
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
  position: relative;
}

.page__body:before {
  content: "";
  z-index: -1;
  opacity: 0;
  background-color: #27282bbf;
  transition: opacity .3s;
  position: absolute;
  inset: 0;
}

.page.open {
  overflow: hidden;
}

.page.open > .page__body:before {
  z-index: 5;
  opacity: 1;
}

.section {
  padding: 4rem 0;
}

@media only screen and (max-width: 1180px) {
  .section {
    padding: 45px 0;
  }
}

@media only screen and (max-width: 992px) {
  .section {
    padding: 30px 0;
  }
}

.container, .wrapper {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.wrapper {
  max-width: 1248px;
}

.image {
  border-radius: var(--radius-main);
  overflow: hidden;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main {
  padding-top: calc(var(--header-height)  + 24px);
}

.logo {
  width: 100%;
  max-width: 236px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-right: 4rem;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .logo {
    max-width: 200px;
    margin-right: 25px;
  }
}

.content {
  align-items: center;
  gap: 60px;
  margin: 0 auto 4rem;
  padding-left: 48px;
  display: flex;
}

.content p {
  width: 100%;
  max-width: 582px;
}

@media only screen and (max-width: 1180px) {
  .content {
    gap: 25px;
    margin-bottom: 30px;
    padding-left: 0;
  }
}

@media only screen and (max-width: 992px) {
  .content {
    flex-direction: column;
  }
}

.is-scrolling {
  scrollbar-color: var(--color-blue-300) var(--color-white);
  scrollbar-width: thin;
  overflow: hidden;
}

.is-scrolling::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.is-scrolling::-webkit-scrollbar-track {
  background-color: var(--color-white);
  border-radius: 10px;
}

.is-scrolling::-webkit-scrollbar-thumb {
  background-color: var(--color-blue-300);
  border-radius: 10px;
}

@media only screen and (max-width: 1280px) {
  .is-scrolling {
    padding: 15px 0;
    overflow-x: scroll;
  }
}

.hidden {
  opacity: 0;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible:focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  width: -moz-fit-content;
  width: fit-content;
  font-family: var(--font-family-secondary);
  font-weight: var(--fw-500);
  letter-spacing: -.05em;
  text-transform: uppercase;
  font-size: clamp(1.875rem, 1.625rem + 1.1111vi, 2.625rem);
  line-height: 1.1;
  overflow: hidden;
}

h2, h3 {
  letter-spacing: 0;
  font-size: clamp(1.5625rem, 1.4167rem + .6481vi, 2rem);
  line-height: 1.2;
}

p {
  font-size: clamp(16px, .9583rem + .1852vi, 18px);
}

.header {
  z-index: 10;
  background-color: var(--color-white);
  width: 100%;
  padding: 24px 0;
  transition-property: background-color, transform;
  transition-duration: .25s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-200%);
}

.header.scroll {
  border-bottom: 1px solid var(--color-white);
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--color-black);
  opacity: .2;
  position: absolute;
  bottom: -24px;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.nav__list {
  align-items: center;
  margin-right: auto;
  display: flex;
}

.nav__item:not(:last-of-type) {
  margin-right: 24px;
}

.nav__link {
  font-weight: var(--fw-400);
  font-size: 16px;
  transition: color .3s linear;
}

.nav__link:hover, .nav__link.true {
  color: var(--color-red-400);
}

.nav__btns {
  align-items: center;
  gap: 16px;
  display: flex;
}

.nav--footer {
  align-items: flex-start;
  padding: 35px 0 65px;
}

.nav--footer:before {
  content: none;
}

@media only screen and (max-width: 1180px) {
  .nav__list {
    margin: 0 auto;
  }

  .nav--footer {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 30px 0;
  }
}

@media only screen and (max-width: 992px) {
  .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.2);
    width: 100%;
    height: var(--vh);
    max-width: 350px;
    background-color: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .25s;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .nav__list {
    z-index: -1;
    flex-direction: column;
    margin: 0;
  }

  .nav__item {
    margin-bottom: 30px;
  }

  .nav__item:not(:last-child) {
    margin-right: 0;
  }

  .nav__link {
    font-size: 20px;
  }

  .nav__btns {
    flex-direction: column;
    gap: 24px;
  }
}

[class].btn {
  border: 2px solid var(--color-red-400);
  border-radius: var(--radius-main);
  width: 100%;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-family: inherit;
  font-weight: var(--fw-500);
  text-transform: uppercase;
  color: var(--red-400);
  background-color: var(--color-white);
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin: 0;
  padding: 4px 14px;
  font-size: 16px;
  line-height: 1.5;
  transition-property: background-color, color, border-color;
  transition-duration: .3s;
  display: flex;
}

[class].btn:after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: currentColor;
  transition: background-color .3s linear;
  position: relative;
  -webkit-mask-image: url("../images/icons/arrow.svg");
  mask-image: url("../images/icons/arrow.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

[class].btn:hover, [class].btn:focus {
  color: var(--color-white);
  background-color: var(--color-red-400);
}

[class].btn--sign {
  color: var(--color-red-400);
}

[class].btn--sign:after {
  content: none;
}

[class].btn--small {
  background-color: #0000;
  border-color: #0000;
  padding: 0;
}

[class].btn--small:hover, [class].btn--small:focus {
  color: var(--color-red-400);
  background-color: #0000;
}

[class].btn--request {
  color: var(--color-white);
  background-color: var(--color-red-400);
}

[class].btn--request:hover, [class].btn--request:focus {
  background-color: var(--color-white);
  color: var(--color-red-400) !important;
}

[class].btn--slider {
  z-index: 2;
  border: 1px solid var(--color-white);
  min-width: 50px;
  min-height: 50px;
  background-color: var(--color-gray-300);
  border-radius: 50%;
  padding: 0;
  position: relative;
}

[class].btn--slider:after {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  -webkit-mask-image: url("../images/icons/arrow-right.svg");
  mask-image: url("../images/icons/arrow-right.svg");
}

.burger {
  --line-height: 2px;
  --burger-size: 20px;
  --move: calc(var(--burger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-red-400);
  width: calc(var(--burger-size)  + 5px);
  height: var(--burger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy__content:not(:last-child) {
  margin-bottom: 24px;
}

.policy h1 {
  margin-bottom: 35px;
  font-size: clamp(1.125rem, .8333rem + 1.2963vi, 2rem);
  line-height: 1.3;
}

.policy h2 {
  margin-bottom: 24px;
  font-size: clamp(1.125rem, 1rem + .5556vi, 1.5rem);
}

.policy ul {
  margin-bottom: 20px;
  padding-left: 15px;
}

.policy li {
  padding-left: 10px;
  font-size: clamp(16px, .9167rem + .3704vi, 20px);
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy li::marker {
  content: "✓";
}

.policy p:not(:last-child) {
  margin-bottom: 20px;
}

.policy a {
  color: var(--color-blue-300);
  text-decoration: underline;
}

.footer {
  background-color: var(--color-blue-600);
}

.footer .logo {
  max-width: 284px;
  margin-right: 20px;
}

.footer__grid {
  grid-template-columns: repeat(4, auto);
  gap: 61px;
  padding-right: 64px;
  display: grid;
}

.footer__block p {
  font-weight: var(--fw-500);
  text-transform: uppercase;
  color: var(--color-white);
  margin-bottom: 16px;
  font-size: 24px;
}

.footer__list {
  gap: 12px;
  display: grid;
}

.footer__list a {
  color: var(--color-white);
  font-size: 16px;
  transition: color .3s linear;
}

.footer__list a:hover {
  color: var(--color-blue-300);
}

.footer__bottom {
  background-color: var(--color-white);
  padding: 16px 0;
}

.footer__copy {
  font-family: var(--font-family-secondary);
  text-transform: uppercase;
  justify-content: space-between;
  gap: 24px;
  display: flex;
}

.footer__copy p, .footer__copy a {
  font-weight: var(--fw-400);
  font-variant: small-caps;
  letter-spacing: .09em;
  text-transform: lowercase;
  font-size: 16px;
}

.footer__policy {
  gap: 64px;
  display: flex;
}

.footer__policy a {
  text-decoration: underline;
  transition: color .3s linear;
}

.footer__policy a:hover {
  color: var(--color-blue-600);
}

@media only screen and (max-width: 1180px) {
  .footer__grid {
    gap: 30px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 992px) {
  .footer__grid {
    width: 100%;
  }

  .footer__policy {
    gap: 24px;
  }

  .footer .logo {
    margin-right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .footer__grid {
    width: -moz-fit-content;
    width: fit-content;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }

  .footer__copy {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 375px) {
  .footer__grid {
    grid-template-columns: 1fr;
  }
}

.contacts {
  z-index: 1;
  padding-bottom: 268px;
  position: relative;
}

.contacts:before {
  content: "";
  z-index: -1;
  background: linear-gradient(199.23deg, #fff 59.715%, #999 110.071%);
  position: absolute;
  inset: 0;
}

.contacts__wrapper {
  position: relative;
}

.contacts__wrapper img {
  width: 100%;
}

.contacts__inner {
  z-index: 1;
  border: 22px solid var(--color-white);
  width: 100%;
  max-width: 498px;
  background: linear-gradient(117deg, #ff5c5c 0%, #f33 100%);
  border-bottom: 0;
  border-right: 0;
  border-radius: 50px;
  padding: 11px 11px 24px 16px;
}

.contacts__title {
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-bottom: 36px;
  display: flex;
}

.contacts__title:before {
  content: "";
  width: 55px;
  height: 55px;
  background-image: url("../images/icons/plus.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.contacts__title h2 {
  color: var(--color-white);
  font-size: clamp(1.875rem, 1.5417rem + 1.4815vi, 2.875rem);
}

.contacts__form {
  width: 100%;
  max-width: 100%;
  gap: 27px;
  display: grid;
}

.contacts__form label {
  width: 100%;
}

.contacts__form input {
  border-bottom: 1px solid var(--color-white);
  width: 100%;
  color: var(--color-white);
  padding-bottom: 8px;
  font-size: 20px;
}

.contacts__form input::placeholder {
  color: var(--color-white);
  font-size: 20px;
}

.contacts__form .btn {
  max-width: 100%;
  border-color: #0000;
  font-size: 24px;
  box-shadow: 0 4px 4px #00000040;
}

.contacts__help {
  z-index: 1;
  width: 651px;
  text-align: right;
  background-color: var(--color-white);
  border-radius: 0 0 0 196px;
  align-self: flex-end;
  padding: 11px 18px 18px 24px;
  position: relative;
  overflow: hidden;
  translate: 0 9px;
}

.contacts__help h2 {
  font-variant: small-caps;
  letter-spacing: -.04em;
  text-transform: none;
  margin-bottom: 10px;
  padding-left: 40px;
  font-size: clamp(1.5625rem, 1.25rem + 1.3889vi, 40px);
  line-height: 1.15;
}

.contacts__help a {
  font-weight: var(--fw-500);
  justify-content: end;
  align-items: center;
  gap: 8px;
  font-size: clamp(1.125rem, 1.0417rem + .3704vi, 1.375rem);
  transition: color .3s linear;
  display: flex;
}

.contacts__help a:before {
  content: "";
  width: 48px;
  height: 48px;
  background-image: url("../images/icons/email.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--color-grey-10);
  position: relative;
}

.contacts__help a:hover {
  color: var(--color-red-400);
}

.contacts__content {
  flex-direction: row-reverse;
  gap: 22px;
  display: flex;
  position: absolute;
  top: 0;
  right: 47px;
}

@media only screen and (max-width: 1180px) {
  .contacts {
    padding-bottom: 300px;
  }

  .contacts__help {
    max-width: 560px;
    translate: 0 -40px;
  }

  .contacts__help h2 {
    padding-left: 0;
    font-size: 26px;
  }

  .contacts__help a {
    font-size: 16px;
  }

  .contacts__help a:before {
    width: 24px;
    height: 24px;
  }

  .contacts__inner {
    border: 5px solid var(--color-white);
  }
}

@media only screen and (max-width: 992px) {
  .contacts {
    padding-bottom: 25px;
  }

  .contacts__help {
    text-align: center;
    border-radius: 0 0 196px 196px;
    align-self: center;
    padding: 16px;
    translate: 0;
  }

  .contacts__help a {
    justify-content: center;
  }

  .contacts__help h2 {
    width: auto;
    text-align: center;
  }

  .contacts__inner {
    max-width: 550px;
  }

  .contacts__content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 0;
  }

  .contacts img {
    object-position: left;
    aspect-ratio: 17 / 6;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 576px) {
  .contacts__help {
    max-width: 100%;
    border-radius: 0 0 80px 80px;
  }
}

.hero__inner {
  justify-content: space-between;
  gap: 24px;
  display: flex;
}

.hero__content {
  width: 100%;
  max-width: 484px;
  translate: 0 33px;
}

.hero h1 {
  margin-bottom: 32px;
}

.hero h1 span {
  color: var(--color-red-400);
}

.hero .btn {
  font-weight: var(--fw-400);
  background-color: var(--color-white);
  padding: 11px 16px;
  font-size: 24px;
  line-height: 1.2;
  color: var(--color-red-400) !important;
}

.hero .btn:hover, .hero .btn:focus {
  background-color: var(--color-red-400);
  color: var(--color-white) !important;
}

.hero--home .hero__inner {
  align-items: center;
}

.hero--home .hero__content {
  translate: 0;
}

.hero--home .hero__content p {
  margin-bottom: 32px;
}

@media only screen and (max-width: 992px) {
  .hero__inner {
    flex-direction: column-reverse;
  }

  .hero__content {
    max-width: 100%;
    text-align: center;
    translate: 0;
  }

  .hero__image img {
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .hero .btn {
    max-width: 100%;
  }
}

.faq {
  padding: 70px 0 64px;
}

.faq .wrapper {
  max-width: 1292px;
}

.faq__content {
  margin-bottom: 57px;
  padding-left: 0;
}

.faq__content p {
  max-width: 712px;
}

.faq__item {
  position: relative;
}

.faq__item:not(:last-child) {
  margin-bottom: 25px;
}

.faq details {
  border: 2px solid var(--color-black);
  border-radius: var(--radius-big);
  overflow: hidden;
}

.faq details[open] summary {
  color: var(--color-blue-300);
}

.faq details[open] summary:after {
  transform: scaleY(-1);
}

.faq summary {
  font-weight: var(--fw-400);
  text-transform: uppercase;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 10px 32px;
  font-size: clamp(1rem, .8333rem + .7407vi, 1.5rem);
  line-height: 1.5;
  transition: color .3s linear;
  display: flex;
}

.faq summary:after {
  content: "";
  width: 32px;
  height: 32px;
  background-color: currentColor;
  transition: transform .3s linear;
  position: absolute;
  top: 14px;
  right: 32px;
  transform: scaleY(1);
  -webkit-mask-image: url("../images/icons/arrow-down.svg");
  mask-image: url("../images/icons/arrow-down.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.faq summary + div {
  padding: 8px 32px 32px;
}

.faq summary:hover {
  color: var(--color-blue-300);
}

@media only screen and (max-width: 992px) {
  .faq {
    padding: 30px 0;
  }

  .faq__content {
    margin-bottom: 30px;
  }

  .faq__content p {
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .faq summary:after {
    width: 25px;
    height: 25px;
    top: 5px;
    right: 10px;
  }
}

.key__inner {
  justify-content: space-between;
  gap: 24px;
  display: flex;
}

.key__list {
  grid-template-columns: repeat(2, auto);
  gap: 24px;
  display: grid;
}

.key__item {
  width: 100%;
  max-width: 762px;
  position: relative;
}

.key__item:first-child {
  max-width: 526px;
  grid-row: 2 span;
}

.key__item--flex .key__desc {
  max-width: 534px;
  border-radius: 0 0 24px;
  top: 0;
}

.key__item--peace .key__desc {
  max-width: 608px;
  border-radius: 24px 0 0;
  bottom: 0;
  left: auto;
  right: 0;
}

.key__desc {
  width: 100%;
  max-width: 441px;
  background: linear-gradient(135deg, #fff 0%, #ffffff80 100%);
  border-radius: 0 24px 24px 0;
  padding: 16px 24px;
  position: absolute;
  top: 24px;
  left: 0;
  overflow: hidden;
}

.key__desc p {
  font-weight: var(--fw-400);
  font-size: 16px;
  line-height: 1.5;
}

.key h3 {
  font-variant: small-caps;
  letter-spacing: .08em;
  text-transform: none;
  margin-bottom: 6px;
}

@media only screen and (max-width: 992px) {
  .key__list {
    grid-template-columns: 1fr;
  }

  .key__item {
    max-width: 100%;
    grid-row: auto;
  }

  .key__item img {
    width: 100%;
    min-height: 210px;
  }

  .key__item:first-of-type {
    max-width: 100%;
    grid-row: auto;
  }

  .key__item:first-of-type img {
    aspect-ratio: 4 / 1;
  }
}

.reviews__content p {
  max-width: 453px;
}

.reviews__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.reviews__item {
  background-color: var(--color-white);
  padding: 16px;
}

.reviews__item p {
  font-weight: var(--fw-400);
  font-size: clamp(1rem, .9167rem + .3704vi, 1.25rem);
}

.reviews__promote {
  border-radius: var(--radius-main);
  text-align: center;
  background-color: #e1e2e4;
  margin-bottom: 30px;
  padding: 12px 16px;
  font-size: clamp(18px, 1.0833rem + .7407vi, 28px);
  font-style: italic;
  display: block;
  overflow: hidden;
}

.reviews__promote--pink {
  color: var(--color-white);
  background-color: #fd4f7e;
}

.reviews__promote--gray {
  background: linear-gradient(270deg, #f2f2f2 0%, #fff 100%);
}

.reviews--about .reviews__content {
  margin-bottom: 60px;
}

.reviews--about .reviews__content p {
  max-width: 736px;
}

.reviews img {
  width: 100%;
  margin-bottom: 32px;
}

.reviews span:not([class]) {
  margin-bottom: 32px;
  font-size: 20px;
  display: block;
}

.reviews h3 {
  text-transform: none;
  margin-bottom: 8px;
  font-size: clamp(18px, .8333rem + 1.2963vi, 32px);
}

@media only screen and (max-width: 992px) {
  .reviews {
    padding: 30px 0;
  }

  .reviews__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .reviews__item {
    padding: 16px;
  }

  .reviews__item img, .reviews__item span:not([class]) {
    margin-bottom: 16px;
  }

  .reviews__item:last-child {
    max-width: 100%;
    text-align: center;
    grid-column: 2 span;
  }

  .reviews__item:last-child img {
    object-position: top;
    aspect-ratio: 2 / 1;
  }

  .reviews__item:last-child h3 {
    width: auto;
  }

  .reviews--about .reviews__content {
    text-align: center;
    margin-bottom: 30px;
  }

  .reviews--about .reviews__content p {
    max-width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .reviews__list {
    grid-template-columns: 1fr;
  }

  .reviews__item {
    padding: 0;
  }

  .reviews__item:last-child {
    text-align: left;
    grid-column: auto;
  }

  .reviews img {
    aspect-ratio: 2 / 1;
    object-position: top;
  }
}

.choose__content {
  margin-bottom: 24px;
}

.choose__content p {
  font-weight: var(--fw-400);
  font-size: 20px;
}

.choose__inner {
  gap: 32px;
  display: flex;
}

.choose__desc {
  width: 100%;
}

.choose__desc:first-of-type {
  max-width: 493px;
  padding: 24px;
}

.choose__desc:last-of-type .choose__list {
  margin-bottom: 24px;
  padding: 24px;
}

.choose__list {
  gap: 14px;
  display: grid;
}

.choose h2 {
  margin-bottom: 24px;
}

.choose h3 {
  font-family: var(--font-family-primary);
  font-weight: var(--fw-700);
  text-transform: none;
  font-size: 17px;
  line-height: 1.5;
}

.choose--external .choose__content p {
  max-width: 640px;
}

.choose--external h2 {
  margin-bottom: 24px;
}

@media only screen and (max-width: 1180px) {
  .choose__desc:first-of-type {
    padding: 5px;
  }

  .choose__desc:last-of-type .choose__list, .choose__list {
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  .choose__inner {
    flex-direction: column;
    gap: 16px;
  }

  .choose__desc:first-of-type {
    max-width: 100%;
  }
}

.slider {
  padding-bottom: 64px;
  position: relative;
  overflow: hidden;
}

.slider__content p {
  max-width: 100%;
}

.slider__item {
  border-radius: calc(var(--radius-main) * 2);
  width: 100%;
  height: auto;
  max-width: 320px;
  background-color: var(--color-gray-300);
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  display: flex;
  overflow: hidden;
}

.slider__item p {
  font-size: 16px;
}

.slider h3 {
  margin-bottom: 8px;
  font-size: clamp(18px, 1rem + .5556vi, 24px);
}

.slider .btn {
  position: absolute;
  top: 50%;
  transform: translateY(50%);
}

.slider .btn.swiper-button-disabled {
  opacity: .2;
  pointer-events: none;
}

.slider .btn--prev {
  left: 18px;
  scale: -1 1;
}

.slider .btn--next {
  left: auto;
  right: 18px;
}

@media only screen and (max-width: 992px) {
  .slider .btn--prev {
    left: 0;
  }

  .slider .btn--next {
    right: 0;
  }
}

@media only screen and (max-width: 576px) {
  .slider .btn {
    transform: translateY(80%);
  }
}

@media only screen and (max-width: 992px) {
  .slider {
    padding-bottom: 30px;
  }
}

.types__wrapper {
  z-index: 1;
  position: relative;
}

.types__wrapper:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--color-black);
  position: absolute;
  top: 0;
}

.types__wrapper:after {
  content: "";
  z-index: -1;
  background: linear-gradient(360deg, #70d2ea45 0%, #7fdaf145 100%);
  position: absolute;
  inset: 0;
}

.types__content {
  margin-bottom: 0;
  padding: 30px 48px;
  position: relative;
}

.types__content .btn {
  font-weight: var(--fw-400);
  margin-left: auto;
  font-size: 24px;
}

.types__content .btn:after {
  width: 15px;
  height: 14px;
}

.types__content p {
  max-width: 431px;
}

.types__list {
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  display: grid;
}

.types__item {
  z-index: 1;
  border-radius: var(--radius-big);
  padding: 16px;
  position: relative;
  overflow: hidden;
}

.types__item:before {
  content: "";
  z-index: -1;
  background: linear-gradient(#9de164 0%, #9adc59 100%);
  position: absolute;
  inset: 0;
}

.types__item p {
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  position: relative;
}

.types__item p:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--color-black);
  position: absolute;
  top: -20px;
}

.types__item--whole:before {
  background: linear-gradient(77deg, #81d2e4 0%, #469db2 100%);
}

.types__item--uni:before {
  background: linear-gradient(0deg, #e8d5d4 0%, #d4aea8 37.37%, #ead4d4 100%);
}

.types__item--custom:before {
  background: linear-gradient(110deg, #fbc668 0%, #dbab34 100%);
}

.types__item .btn {
  font-weight: var(--fw-400);
  margin-bottom: 32px;
}

.types h3 {
  letter-spacing: -.05em;
  color: var(--color-white);
  margin-bottom: 32px;
  font-size: clamp(24px, 1rem + 2.2222vi, 48px);
}

.types img {
  margin-bottom: 16px;
}

@media only screen and (max-width: 1180px) {
  .types__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .types__list img {
    width: 100%;
    object-position: center;
    aspect-ratio: 2 / 1;
  }
}

@media only screen and (max-width: 992px) {
  .types__content {
    gap: 16px;
    padding: 30px 0;
  }
}

@media only screen and (max-width: 576px) {
  .types__list {
    grid-template-columns: 1fr;
  }

  .types img {
    aspect-ratio: 2 / 1;
  }
}

.values {
  padding: 64px 0 90px;
}

.values__content {
  margin-bottom: 24px;
}

.values__content p {
  max-width: 768px;
}

.values__list {
  grid-template-columns: repeat(5, 1fr);
  gap: 24px;
  margin-bottom: 24px;
  display: grid;
}

.values__desc {
  border-radius: var(--radius-main);
  background-color: var(--color-brown-300);
  padding: 24px 46px;
  overflow: hidden;
}

.values__desc p {
  font-weight: var(--fw-400);
  font-size: clamp(1.125rem, 1.0833rem + .1852vi, 1.25rem);
  line-height: 1.2;
}

.values li {
  border-radius: var(--radius-main);
  background-color: var(--color-gray-100);
  flex-direction: column;
  justify-content: center;
  padding: 8px 10px;
  display: flex;
  overflow: hidden;
}

.values li p {
  font-weight: var(--fw-400);
  font-size: 17px;
  line-height: 1.5;
}

.values span {
  font-weight: var(--fw-700);
  font-size: 17px;
  line-height: 1.5;
}

@media only screen and (max-width: 1180px) {
  .values {
    padding: 40px 0;
  }
}

@media only screen and (max-width: 992px) {
  .values {
    padding: 30px 0;
  }

  .values__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .values__desc {
    padding: 16px;
  }

  .values li:last-child {
    text-align: center;
    grid-column: 2 span;
  }
}

@media only screen and (max-width: 576px) {
  .values__list {
    grid-template-columns: 1fr;
  }

  .values li:last-child {
    text-align: left;
    grid-column: auto;
  }
}

.missions__list {
  gap: 24px;
  display: flex;
}

.missions__item {
  border-radius: calc(var(--radius-main) * 2);
  width: 100%;
  max-width: 442px;
  color: var(--color-white);
  background: linear-gradient(134deg, #ff5c5c 0%, #f33 100%);
  padding: 24px 24px 32px;
  overflow: hidden;
}

.missions__item--colored {
  max-width: 846px;
  background: linear-gradient(332deg, #81d2e4 0%, #469db2 100%);
}

.missions h3 {
  text-transform: capitalize;
  margin-bottom: 24px;
}

.missions p {
  font-weight: var(--fw-400);
  font-size: clamp(1.125rem, 1.0833rem + .1852vi, 1.25rem);
}

@media only screen and (max-width: 992px) {
  .missions__list {
    flex-direction: column;
  }

  .missions__item {
    max-width: 100%;
    padding: 16px;
  }
}

.about {
  padding: 3.3125rem 0 4rem;
}

.about__content {
  margin-bottom: 39px;
}

.about__content p {
  max-width: 740px;
}

.about__inner {
  align-items: center;
  gap: 24px;
  display: flex;
}

.about__desc {
  width: 100%;
  max-width: 445px;
  padding: 24px;
}

.about h3 {
  margin-bottom: 24px;
}

.about p:first-of-type {
  margin-bottom: 20px;
}

@media only screen and (max-width: 992px) {
  .about {
    padding: 30px 0;
  }

  .about__inner {
    flex-direction: column;
  }

  .about__content {
    margin-bottom: 30px;
  }

  .about__content p {
    max-width: 100%;
  }

  .about__desc {
    max-width: 100%;
    padding: 0;
  }

  .about__image img {
    width: 100%;
  }
}

.products {
  padding-bottom: 4rem;
}

.products__content {
  margin-bottom: 0;
  padding: 31px 48px;
}

.products__content p {
  max-width: 812px;
}

.products__list {
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 8px;
  display: grid;
}

.products__item {
  position: relative;
}

.products__item--whole .products__desc {
  border-radius: 0 0 0 24px;
  left: auto;
  right: 0;
}

.products__item--unilife .products__desc {
  border-radius: 0 24px 0 0;
  top: 24px;
  bottom: 0;
}

.products__item--custom .products__desc {
  border-radius: 24px 0 0;
  inset: 24px 0 0 auto;
}

.products__desc {
  width: 100%;
  max-width: 485px;
  background: linear-gradient(142deg, #fff 0%, #ffffff80 100%);
  border-radius: 0 0 24px;
  padding: 16px 24px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.products__desc p {
  font-weight: var(--fw-400);
  font-size: clamp(1rem, .9167rem + .3704vi, 1.25rem);
  line-height: 1.2;
}

.products__desc p:not(:last-child) {
  margin-bottom: 20px;
}

.products__desc h3 {
  margin-bottom: 24px;
}

.products__description {
  color: var(--color-white);
  background: linear-gradient(167deg, #69b35c 0%, #3c7f38 100%);
  border-radius: 24px 24px 100px 100px;
  padding: 24px 48px;
}

.products__description p {
  font-weight: var(--fw-400);
  text-align: center;
  font-size: clamp(1rem, .9167rem + .3704vi, 1.25rem);
  line-height: 1.2;
}

@media only screen and (max-width: 1280px) {
  .products {
    padding-bottom: 30px;
  }

  .products__desc {
    padding: 16px;
  }
}

@media only screen and (max-width: 1180px) {
  .products__content {
    flex-direction: column;
    padding: 30px 0;
  }

  .products__list {
    grid-template-columns: 1fr;
  }

  .products img {
    width: 100%;
    min-height: 460px;
    aspect-ratio: 16 / 9;
  }
}

@media only screen and (max-width: 768px) {
  .products__desc {
    max-width: 100%;
  }

  .products__description {
    border-radius: 20px 20px 60px 60px;
    padding: 24px;
  }
}

.benefits {
  margin-bottom: 64px;
}

.benefits__list {
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  display: grid;
}

.benefits__item {
  background-color: var(--color-gray-300);
  border-radius: 16px;
  padding: 24px;
  overflow: hidden;
}

.benefits__item ul {
  gap: 18px;
  padding-left: 27px;
  display: grid;
}

.benefits__item ul li {
  position: relative;
}

.benefits__item ul li:before {
  content: "";
  width: 7px;
  height: 7px;
  background-color: var(--color-black);
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: -17px;
}

.benefits__item ul p, .benefits__item ul span {
  font-size: 18px;
}

.benefits__item ul span {
  font-weight: var(--fw-500);
}

.benefits h3 {
  margin-bottom: 8px;
  font-size: clamp(1rem, .8333rem + .7407vi, 1.5rem);
  line-height: 1.5;
}

.benefits h2 {
  margin-bottom: 32px;
}

@media only screen and (max-width: 992px) {
  .benefits {
    margin-bottom: 30px;
  }

  .benefits__item {
    padding: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .benefits__list {
    grid-template-columns: 1fr;
  }
}

.additional {
  padding: 32px 0;
}

.additional__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.additional__item {
  border-radius: 16px;
  padding: 24px;
  overflow: hidden;
}

.additional__item:nth-child(2n+1) {
  border: 1px solid var(--color-blue-600);
}

.additional__title {
  justify-content: center;
  gap: 26px;
  margin-bottom: 24px;
  display: flex;
}

.additional__title:before {
  content: "";
  width: 60px;
  height: 60px;
  background-image: url("../images/icons/death.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  display: block;
  position: relative;
}

.additional__title--child:before {
  background-image: url("../images/icons/child.svg");
}

.additional__title--death:before {
  background-image: url("../images/icons/rider.svg");
}

.additional__title--long:before {
  background-image: url("../images/icons/calendar.svg");
}

.additional__title--premium:before {
  background-image: url("../images/icons/premium.svg");
}

.additional__title--pill:before {
  background-image: url("../images/icons/pill.svg");
}

.additional h3 {
  letter-spacing: -.02em;
  font-size: clamp(1rem, .8333rem + .7407vi, 24px);
  line-height: 1.5;
}

.additional h2 {
  margin-bottom: 64px;
}

@media only screen and (max-width: 1180px) {
  .additional h2 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 992px) {
  .additional__item {
    padding: 16px;
  }

  .additional__title {
    gap: 16px;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .additional__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 576px) {
  .additional__list {
    grid-template-columns: 1fr;
  }

  .additional__title {
    align-items: center;
  }
}

/*# sourceMappingURL=main.css.map */
