@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

h1,
h2,
h3 {
  overflow: hidden;
  width: fit-content;
  font-family: var(--font-family-secondary);
  font-weight: var(--fw-500);
  font-size: clamp(1.875rem, 1.625rem + 1.1111vi, 2.625rem);
  line-height: 1.1;
  letter-spacing: -0.05em;
  text-transform: uppercase;
}

h2,
h3 {
  font-size: clamp(1.5625rem, 1.4167rem + 0.6481vi, 2rem);
  line-height: 1.2;
  letter-spacing: 0;
}


p {
  font-size: clamp(16px, 0.9583rem + 0.1852vi, 18px);
}
